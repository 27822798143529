var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"headers":_vm.headers,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
        itemsPerPageText: '',
      },"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0,"item-class":_vm.rowClassControl},on:{"update:options":function($event){_vm.options=$event},"click:row":function (ref) {
            var id = ref.id;

            return _vm.onDetail(id);
},"update:page":function (page) { return _vm.onPagination({ page: page }); },"update:items-per-page":function (size) { return _vm.onPagination({ size: size }); }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('FilterRow',{attrs:{"items":_vm.headers},on:{"input":_vm.onPagination}})]},proxy:true},{key:"item.status",fn:function(ref){
      var status = ref.item.status;
return [_c('StatusChip',{attrs:{"value":status}})]}},{key:"item.work",fn:function(ref){
      var work = ref.item.work;
return [_c('WorkChip',{attrs:{"value":work}})]}},{key:"item.place",fn:function(ref){
      var place = ref.item.place;
return [_c('PlaceChip',{attrs:{"value":place}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }