var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.$route.meta.title)?_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$route.meta.title))]):_vm._e(),_c('div',{staticClass:"mb-4 clearfix"},[_c('VBtn',{staticClass:"float-right ml-2",attrs:{"depressed":"","color":"success"},on:{"click":_vm.onCreate}},[_vm._v(" Новое согласование ")]),_c('VBtn',{attrs:{"depressed":""},on:{"click":_vm.toHome}},[_c('VIcon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}}),_vm._v(" На главную ")],1)],1),_c('AcceptanceList',{attrs:{"headers":[
        { text: 'Номер', value: 'id', sortable: false },
        { text: 'Дата изменения', value: 'updated', sortable: false },
        { text: 'Номер договора', value: 'contract', sortable: false },
        { text: 'Номер доп. соглашения', value: 'agreement', sortable: false },
        { text: 'Вид работ', value: 'work', options: _vm.works, sortable: false },
        { text: 'Цех', value: 'place', options: _vm.places, sortable: false },
        { text: 'ФИО инициатора', value: 'initiator', sortable: false },
        { text: 'Статус', value: 'status', options: _vm.statuses, sortable: false } ],"items":_vm.acceptances.items,"count":_vm.acceptances.count,"page":_vm.page,"size":_vm.size,"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }